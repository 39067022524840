import * as actionsType from "../actions/actionsType";

const homeReducer = (
  state = {
    message: null,
    color: null,
    loading: false
  },
  action
) => {
  switch (action.type) {
    case actionsType.SET_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case actionsType.SET_MESSAGE:
      return {
        ...state,
        message: action.messageText,
        color: action.messageColor
      };
    default:
      return state;
  }
};

export default homeReducer;
