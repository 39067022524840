import React from "react";

function CustomButton({ children, clickHandler, color, disabled }) {
  return (
    <button
      className="my-2 px-4 py-3 rounded-[25px] text-xs w-full focus:outline-none"
      onClick={clickHandler}
      disabled={disabled}
      style={
        disabled
          ? {
              cursor: "not-allowed",
              color: color,
              background: "#000",
            }
          : { color: color, background: "#000" }
      }
    >
      {children}
    </button>
  );
}

export default CustomButton;
