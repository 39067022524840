import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { allRoutes } from "./routes";
import { ToastContainer } from "react-toastify";
import CustomLoading from "./components/Shared/CustomLoading";
import { getInit } from "./store/actions/competitions";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const [searchParams] = useSearchParams();
  const { loading } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    let tokenParams = searchParams.get("token");
    let tokenStorage = localStorage.getItem("token");
    if (tokenParams) {
      localStorage.setItem("token", tokenParams);
      dispatch(getInit(navigate));
    } else if (!tokenStorage) {
      navigate("/error");
    } else {
      dispatch(getInit(navigate));
    }
  }, [dispatch, navigate, searchParams]);

  return (
    <React.Fragment>
      {loading && <CustomLoading />}
      {allRoutes}
      <ToastContainer autoClose={2000} position="top-right" limit={1} />
    </React.Fragment>
  );
};

export default App;
