import * as actionsType from "./actionsType";
import { toast } from "react-toastify";

export const setLoading = loading => dispatch => {
  dispatch({
    type: actionsType.SET_LOADING,
    loading
  });
};

export const setMessage = ({ text, color }) => dispatch => {
  if (text && color) {
    if (color === "green") {
      toast.success(text);
    } else {
      toast.error(text);
    }
  }
};
