import React from "react";
import VideoCard from "./VideoCard";

function HomeList({ videos, defaultLogo, primaryColor, secondaryColor }) {
  return (
    <div>
      {videos.map((video, index) => (
        <div key={video._id}>
          <VideoCard
            video={video}
            rank={index + 1}
            defaultLogo={defaultLogo}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
          />
        </div>
      ))}
    </div>
  );
}

export default HomeList;
