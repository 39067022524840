import * as actionsType from "../actions/actionsType";

const competitionsReducer = (
  state = {
    company: null,
    competitions: null,
    competition: null,
    homeVideos: null,
    winners: null,
    countDown: null,
    isFinished: false,
  },
  action
) => {
  switch (action.type) {
    case actionsType.SET_FINISHED:
      return {
        ...state,
        isFinished: action.payload,
      };
    case actionsType.GET_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case actionsType.GET_COMPETITIONS:
      return {
        ...state,
        competitions: action.payload,
      };
    case actionsType.GET_COMPETITION:
      return {
        ...state,
        competition: action.payload,
      };
    case actionsType.GET_HOME_VIDEOS:
      return {
        ...state,
        homeVideos: action.payload,
      };
    case actionsType.GET_WINNERS:
      return {
        ...state,
        winners: action.payload,
      };
    case actionsType.GET_COUNTDOWN:
      return {
        ...state,
        countDown: action.payload,
      };
    default:
      return state;
  }
};

export default competitionsReducer;
