import axios from "axios";
const API = axios.create({
  baseURL: "https://backend.shaga3app.com/api/",
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("token")) {
    req.headers = {
      ...req.headers,
      "X-Auth-Token": `${localStorage.getItem("token")}`,
    };
  }
  return req;
});

export const init = (data) => API.get("/init", { params: { ...data } });
export const getHomeVideos = (data) =>
  API.get("/home/top", {
    params: { ...data, type: "top", page: 1, perPage: 10 },
  });
export const getCountdown = (data) =>
  API.get("/countdown", { params: { ...data } });
export const getWinners = (id) => API.get(`/winners/${id}`);
