import { Routes, Route, Navigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
import Competitions from "./views/Competitions";
import Home from "./views/Home";
import Winners from "./views/Winners";
import Error from "./views/Error";

export const allRoutes = (
  <Routes>
    <Route exact path="/" element={<Competitions />} />
    <Route exact path="/error" element={<Error />} />
    <Route path="/home/:id" element={<Home />} />
    <Route path="/winners/:id" element={<Winners />} />
    <Route path="*" element={<Navigate replace to="/" />} />
  </Routes>
);
