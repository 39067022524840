// shared
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_LOADING = "SET_LOADING";

export const SET_FINISHED = "SET_FINISHED";
export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPETITIONS = "GET_COMPETITIONS";
export const GET_COMPETITION = "GET_COMPETITION";
export const GET_HOME_VIDEOS = "GET_HOME_VIDEOS";
export const GET_WINNERS = "GET_WINNERS";
export const GET_COUNTDOWN = "GET_COUNTDOWN";
