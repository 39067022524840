import React from "react";
import Competition from "./Competition";

function Competitions({ company, competitions, openCompetitionHandler }) {
  return (
    <div>
      <div className="text-center mb-5">
        <div className="w-1/2 md:w-[200px] h-full mx-auto mb-5">
          <img
            className="w-full h-full"
            src={company.options.logos.brand}
            alt={company.slug}
          />
        </div>
        <h2 className="text-white text-2xl font-bold">{company.name}</h2>
      </div>
      {competitions.length !== 0 && (
        <div className="w-full md:w-1/2 mx-auto">
          {competitions.map((comp) => (
            <>
              {comp.isActive && (
                <div key={comp._id}>
                  <Competition
                    competition={comp}
                    openCompetitionHandler={openCompetitionHandler}
                  />
                </div>
              )}
            </>
          ))}
        </div>
      )}
    </div>
  );
}

export default Competitions;
