import React from "react";
import Image from "../assets/images/logo.png";
import main from "../assets/images/main.png";
function Error() {
  return (
    <div
      style={{
        backgroundImage: `url(${main})`,
        backgroundPosition: "top center",
      }}
      className="w-screen h-screen bg-no-repeat bg-cover overflow-y-auto"
    >
      <main>
        <div className="max-w-5xl mx-auto py-6 sm:px-6 lg:px-8 h-screen flex flex-col justify-center items-center space-y-4">
          <div className="w-[40%] mx-auto">
            <img className="w-full h-full" src={Image} alt="shag3app" />
          </div>
          <div className="w-full text-center">
            <h2 className="text-3xl font-bold text-white">
              Error something went wrong please try again
            </h2>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Error;
