import React from "react";
import Image from "../../assets/images/logo.png";

function CustomLoading() {
  return (
    <div className="fixed top-0 left-0 flex flex-col justify-center items-center w-[100vw] h-[100vh] bg-black/[0.6] z-50">
      <img src={Image} alt="loading" className="w-[50px]" />
      <h5 className="text-white text-xl">Loading...</h5>
    </div>
  );
}

export default CustomLoading;
