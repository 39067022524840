import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CompetitionsComponent from "../components/competitions/Competitions";
import localBgImage from "../assets/images/main.png";

function Competitions() {
  const [bgImage, setBgImage] = useState(localBgImage);
  const navigate = useNavigate();
  const companyData = useSelector((state) => state.competitions.company);
  const competitionsData = useSelector(
    (state) => state.competitions.competitions
  );
  const openCompetition = (id) => {
    localStorage.setItem("comp", id);
    navigate(`/home/${id}`);
  };

  useEffect(() => {
    if (companyData) {
      if (companyData.backgroundImage) {
        setBgImage(companyData.backgroundImage);
      }
    }
  }, [companyData]);

  return (
    <div
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundPosition: "top center",
      }}
      className="w-screen h-screen bg-no-repeat bg-cover overflow-y-auto"
    >
      <main>
        <div className="max-w-5xl mx-auto py-6 sm:px-6 lg:px-8">
          <div>
            {companyData && competitionsData && (
              <CompetitionsComponent
                company={companyData}
                competitions={competitionsData}
                openCompetitionHandler={openCompetition}
              />
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Competitions;
