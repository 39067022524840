import React, { useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getWinners } from "../store/actions/competitions";
import WinnersList from "../components/Winners/WinnersList";

function Winners() {
  const dispatch = useDispatch();
  const competitionData = useSelector(
    (state) => state.competitions.competition
  );
  const winnersData = useSelector((state) => state.competitions.winners);
  let { id } = useParams();

  useEffect(() => {
    dispatch(getWinners(id));
  }, [dispatch, id]);

  return (
    <div>
      {competitionData && (
        <div className="h-full bg-[#FAFAFA]">
          <Navbar competition={competitionData} />
          <main>
            {winnersData && (
              <div className="max-w-5xl mx-auto py-6 sm:px-6 lg:px-8">
                <WinnersList
                  winners={winnersData}
                  defaultLogo={competitionData.settings.logos.brand}
                  primaryColor={competitionData.settings.primaryColor}
                  secondaryColor={competitionData.settings.secondaryColor}
                />
              </div>
            )}
          </main>
        </div>
      )}
    </div>
  );
}

export default Winners;
