import React from "react";
import calendarIcon from "../../assets/images/calendar.svg";
import backs from "../../assets/images/backs.png";
import CustomButton from "../Shared/CustomButton";
import { Link } from "react-router-dom";

function Competition({ competition, openCompetitionHandler }) {
  return (
    <div className="flex justify-around items-start mx-2 my-3 text-white">
      <div
        className="w-[70px] h-[70px] bg-no-repeat bg-contain p-[10px] flex justify-center items-center"
        style={{ backgroundImage: `url(${backs})`, backgroundPosition: "50%" }}
      >
        <button
          className="focus:outline-none"
          onClick={() => openCompetitionHandler(competition._id)}
        >
          <div className="w-[50px] h-[50px]  overflow-hidden">
            <img
              className="w-full h-full rounded-full"
              src={competition.settings.logos.competition}
              alt={competition.name}
            />
          </div>
        </button>
      </div>
      <div className="flex-1 flex flex-col justify-start items-start m-[10px]">
        {competition.isFinished && (
          <div>
            <span class="w-[35px] h-[16.31px] text-[8px] rounded-[15px] uppercase py-[3px] px-[5px] z-10 my-[5px] mx-auto text-white bg-[#f01136]">
              completed
            </span>
          </div>
        )}
        <div className="mt-2 mb-[10px]">
          <button
            className="focus:outline-none"
            onClick={() => openCompetitionHandler(competition._id)}
          >
            <h5 className="text-sm font-bold">{competition.name}</h5>
          </button>
        </div>
        <div className="flex justify-start items-center mb-[10px] w-full">
          <div>
            <img src={calendarIcon} alt="calendar" />
          </div>
          <div className="flex-1 mx-2">
            <span className="text-xs font-semibold">
              {new Date(competition.countdown).toLocaleDateString("en-EG", {
                weekday: "short",
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </span>
          </div>
        </div>
        <div className="mb-[10px]">
          <p className="text-xs font-semibold break-all">
            {competition.description}
          </p>
        </div>
        <div className="text-start">
          <CustomButton
            color={competition.settings.primaryColor}
            bgColor={competition.settings.secondaryColor}
            clickHandler={() => openCompetitionHandler(competition._id)}
          >
            Open Competition
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

export default Competition;
