import * as api from "../api";
import * as actionsType from "./actionsType";
import { setLoading, setMessage } from "./home";

// company
export const getInit = (navigate) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COMPANY,
      payload: null,
    });
    dispatch({
      type: actionsType.GET_COMPETITIONS,
      payload: null,
    });
    let navigator_info = window.navigator;
    let screen_info = window.screen;
    let uid = navigator_info.mimeTypes.length || "";
    uid += navigator_info.userAgent.replace(/\D+/g, "") || "";
    uid += navigator_info.plugins.length;
    uid += screen_info.height || "";
    uid += screen_info.width || "";
    uid += screen_info.pixelDepth || "";
    let params = {
      foeihq: uid,
    };
    dispatch(setLoading(true));
    const { data } = await api.init(params);
    let competitionId = localStorage.getItem("comp");
    if (competitionId) {
      let selectedCompetition = null;
      selectedCompetition = data.competitions.find(
        (comp) => comp._id === competitionId
      );
      if (selectedCompetition) {
        if (selectedCompetition !== -1) {
          dispatch({
            type: actionsType.GET_COMPETITION,
            payload: selectedCompetition,
          });
        }
      }
    }
    dispatch({
      type: actionsType.GET_COMPANY,
      payload: data.company,
    });
    dispatch({
      type: actionsType.GET_COMPETITIONS,
      payload: data.competitions,
    });
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoading(false));
    dispatch(setMessage({ text: error.message, color: "red" }));
    navigate("/error");
  }
};
// homeVideos
export const getHomeVideos = (id) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_HOME_VIDEOS,
      payload: null,
    });
    dispatch(setLoading(true));
    const { data } = await api.getHomeVideos({ competition_id: id });
    dispatch({
      type: actionsType.GET_HOME_VIDEOS,
      payload: data[0].videos,
    });
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoading(false));
    dispatch(setMessage({ text: error.message, color: "red" }));
  }
};
// winners
export const getWinners = (id) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_WINNERS,
      payload: null,
    });
    dispatch(setLoading(true));
    const { data } = await api.getWinners(id);
    dispatch({
      type: actionsType.GET_WINNERS,
      payload: data.winners,
    });
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoading(false));
    dispatch(setMessage({ text: error.message, color: "red" }));
  }
};
// countdown
export const getCountDown = (id) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COUNTDOWN,
      payload: null,
    });
    dispatch(setLoading(true));
    const { data } = await api.getCountdown({ competition_id: id });
    dispatch({
      type: actionsType.GET_COUNTDOWN,
      payload: data,
    });
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoading(false));
    dispatch(setMessage({ text: error.message, color: "red" }));
  }
};
// finished
export const setFinished = (value) => async (dispatch) => {
  dispatch({
    type: actionsType.SET_FINISHED,
    payload: value,
  });
};
