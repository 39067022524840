import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Countdown from "react-countdown";
import { useParams } from "react-router-dom";
import { getCountDown, setFinished } from "../../store/actions/competitions";

function CountdownComponent() {
  const [checkIsFinished, setIsFinished] = useState(false);
  const [winnerName, setWinnerName] = useState(null);
  const dispatch = useDispatch();
  const countdownData = useSelector((state) => state.competitions.countDown);
  let { id } = useParams();
  useEffect(() => {
    dispatch(getCountDown(id));
  }, [dispatch, id]);

  const ws = useRef(null);

  useEffect(() => {
    ws.current = new WebSocket("wss://backend.shaga3app.com");
    ws.current.onopen = () => console.log("ws opened");
    ws.current.onclose = () => console.log("ws closed");

    const wsCurrent = ws.current;

    return () => {
      wsCurrent.close();
    };
  }, []);

  useEffect(() => {
    if (!ws.current) return;

    ws.current.onmessage = (e) => {
      const data = JSON.parse(e.data);
      const competitionId = localStorage.getItem("comp");
      if (data.event) {
        if (data.event === "competition-success") {
          if (competitionId === data.identifier) {
            if (data.winnerName.length !== 0) {
              setWinnerName(data.winnerName);
            } else {
              setWinnerName("Winner");
            }
            dispatch(setFinished(true));
            ws.current.close();
          }
        }
      }
    };
  }, [dispatch]);

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setIsFinished(true);
    } else {
      return (
        <div>
          <div>
            <span className="text-[9.6px] lg:text-sm text-white mx-[5px]">
              THIS ROUND ENDS IN
            </span>
          </div>
          <div className="flex justify-center items-center space-x-3">
            <div className="flex flex-col justify-between items-center space-y-2">
              <div className="bg-white/10 rounded-[3px] text-[15px] w-[26px] font-semibold text-center text-white px-1">
                <span>{days}</span>
              </div>
              <div className="text-white text-[10px]">
                <span>D</span>
              </div>
            </div>
            <div className="flex flex-col justify-between items-center space-y-2">
              <div className="bg-white/10 rounded-[3px] text-[15px] w-[26px] font-semibold text-center text-white px-1">
                <span>{hours}</span>
              </div>
              <div className="text-white text-[10px]">
                <span>H</span>
              </div>
            </div>
            <div className="flex flex-col justify-between items-center space-y-2">
              <div className="bg-white/10 rounded-[3px] text-[15px] w-[26px] font-semibold text-center text-white px-1">
                <span>{minutes}</span>
              </div>
              <div className="text-white text-[10px]">
                <span>M</span>
              </div>
            </div>
            <div className="flex flex-col justify-between items-center space-y-2">
              <div className="bg-white/10 rounded-[3px] text-[15px] w-[26px] font-semibold text-center text-white px-1">
                <span>{seconds}</span>
              </div>
              <div className="text-white text-[10px]">
                <span>S</span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  if (countdownData) {
    if (countdownData.isFinished) {
      return (
        <div className="my-[20px]">
          <span className="text-white font-bold">Competition Ended</span>
        </div>
      );
    }
    if (countdownData.isLocked) {
      return (
        <div className="my-[20px]">
          <span className="text-white font-bold">Calculating the winner</span>
        </div>
      );
    }
  }
  if (winnerName) {
    return (
      <div className="my-[20px]">
        <h5 className="text-white font-bold">Congratulations &#127881;</h5>
        <h3 className="text-white font-bold">{winnerName}</h3>
      </div>
    );
  }
  if (checkIsFinished) {
    return (
      <div className="my-[20px]">
        <span className="text-white font-bold">Calculating the winner</span>
      </div>
    );
  }

  return (
    <div>
      {countdownData && !countdownData.isFinished && !countdownData.locked && (
        <Countdown date={countdownData.countdown * 1000} renderer={renderer} />
      )}
    </div>
  );
}

export default CountdownComponent;
