import React from "react";
import WinnerCard from "./WinnerCard";

function WinnersList({ winners, defaultLogo, primaryColor, secondaryColor }) {
  if (winners.length === 0) {
    return (
      <div className="w-full text-center mb-[8px]">
        <span className="text-[28px] font-bold">
          NO winners in this competition Yet
        </span>
      </div>
    );
  }
  return (
    <div>
      {winners.map((winner, index) => (
        <div key={winner._id} className="max-w-[470px] mx-auto">
          <div className="w-full flex justify-start items-center space-x-2 my-2 px-2 rounded-[5px] bg-white border-solid md:border-[1px] border-[#dbdbdb]">
            <svg
              width="14"
              height="17"
              viewBox="0 0 14 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ margin: "8px 5px", width: "12px", height: "auto" }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.54545 0.772727C1.54545 0.345962 1.19949 0 0.772727 0C0.345962 0 0 0.345962 0 0.772727V16.2273C0 16.654 0.345962 17 0.772727 17C1.19949 17 1.54545 16.654 1.54545 16.2273V8.5H7.72727V9.27273C7.72727 9.6995 8.07322 10.0455 8.5 10.0455H12.3636C12.6233 10.0455 12.8658 9.91494 13.0087 9.69811C13.1517 9.48129 13.1762 9.20705 13.0738 8.96835L12.0453 6.56818L13.0738 4.16803C13.1762 3.92929 13.1517 3.6551 13.0087 3.43826C12.8658 3.22141 12.6233 3.09091 12.3636 3.09091H9.27273V2.31818C9.27273 1.89142 8.92678 1.54545 8.5 1.54545H1.54545V0.772727ZM7.72727 3.09091H1.54545V6.95455H7.72727V3.09091ZM9.27273 8.5H11.1918L10.4943 6.87257C10.411 6.67819 10.411 6.45817 10.4943 6.26379L11.1918 4.63636H9.27273V8.5Z"
                fill={primaryColor}
              />
            </svg>
            <span className="text-[20px] font-bold">
              {" "}
              Round {winners.length - index}
            </span>
          </div>
          {winner.winners.length > 0 ? (
            <div>
              {winner.winners.map((w, index) => (
                <WinnerCard
                  key={w._id}
                  winner={w}
                  winnerLogo={winner.winnerLogos[index]}
                  defaultLogo={defaultLogo}
                  primaryColor={primaryColor}
                  secondaryColor={secondaryColor}
                />
              ))}
            </div>
          ) : (
            <div className="w-full text-center mb-[8px]">
              <span className="text-[20px] font-bold">
                no winners in this round
              </span>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default WinnersList;
