import React, { useState, useEffect } from "react";
import Prize from "../../assets/images/prize.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

function WinnerCard({
  winner,
  winnerLogo,
  defaultLogo,
  primaryColor,
  secondaryColor,
}) {
  const [videoImage, setVideoImage] = useState(null);
  useEffect(() => {
    if (winner) {
      fetch(`https://api.vimeo.com/videos/${winner.video_id.uri}/pictures`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer c45bc0c3d9cfb0cd1e56ba3f84ca84d0",
        },
      })
        .then((results) => results.json())
        .then((data) => {
          let imgs = data.data[0].sizes;
          let videoImg = imgs[Math.floor(imgs.length / 2)];
          setVideoImage(videoImg.link);
        })
        .catch((err) => console.log("error", err));
    }
  }, [winner]);
  return (
    <div className="max-w-[470px] mx-auto mb-3 rounded-[8px] bg-white border-solid md:border-[1px] border-[#dbdbdb]">
      {/* client */}
      <div className="flex justify-between items-center my-2 px-2">
        <div className="flex-1 flex justify-start items-center space-x-2">
          <div className="w-[45px] h-[45px]">
            <img
              className="w-full object-cover p-[2px] rounded-full"
              style={{ border: "2px solid " + primaryColor }}
              src={
                winner.user_id.avatar !== null
                  ? winner.user_id.avatar
                  : defaultLogo
              }
              alt={winner.user_id.name}
              onError={(e) => {
                e.currentTarget.src = defaultLogo;
              }}
            />
          </div>
          <div>
            <h5>{winner.user_id.name}</h5>
          </div>
        </div>
        <div className="w-[30px] h-[30px]">
          <img
            className="w-full object-cover"
            src={winnerLogo !== null ? winnerLogo : Prize}
            alt="Prize"
            onError={(e) => {
              e.currentTarget.src = Prize;
            }}
          />
        </div>
      </div>
      {/* video object */}
      <div>
        <div className="relative">
          {videoImage ? (
            <div>
              <div>
                <LazyLoadImage src={videoImage} alt={winner.video_id._id} />
              </div>
            </div>
          ) : (
            <div className="bg-black text-center min-h-[200px] flex flex-col justify-center items-center space-y-2">
              <img src={defaultLogo} alt="loading" className="w-[100px]" />
              <h5 className="text-white text-xl">Loading...</h5>
            </div>
          )}
          {winner.video_id.isFeatured && (
            <div className="absolute top-[10px] right-[10px] bg-[rgba(17,17,17,0.8)] text-start h-[34px] w-auto z-20 py-[5px] px-[8px] rounded-[15px]">
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Icons/award-4"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="medal-with-a-star-hanging-of-a-gross-ribbon"
                    transform="translate(6.541899, 0.000000)"
                    fill="#FCB434"
                  >
                    <path
                      d="M5.26364246,13.9541899 C8.0190838,13.9541899 10.2536313,16.187933 10.2536313,18.9451173 C10.2536313,21.7013631 8.0190838,23.9351061 5.26364246,23.9351061 C2.50739665,23.9351061 0.273251397,21.7005587 0.273251397,18.9451173 C0.273251397,16.187933 2.50739665,13.9541899 5.26364246,13.9541899 Z M5.26364246,16.1750615 L4.36317318,17.9974525 L2.35025698,18.2912179 L3.80715084,19.7111061 L3.46471508,21.7153073 L5.26364246,20.7689832 L7.06310615,21.7169162 L6.72026816,19.7120447 L8.17675978,18.2921564 L6.16424581,17.9990615 L5.26364246,16.1750615 Z M8.07043575,2.39808173e-14 L10.4479106,11.6621229 L5.26364246,13.7631285 L2.45711732,2.39808173e-14 L8.07043575,2.39808173e-14 Z M2.17099441,1.40540782 L4.63977654,13.5109274 L0.0793743017,11.6621229 L2.17099441,1.40540782 Z"
                      id="Combined-Shape"
                    ></path>
                  </g>
                </g>
              </svg>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-between items-center my-[8px] mx-[20px]">
        {/* caption */}
        <div className="my-[8px] mx-[20px]">
          <p className="text-[15px] font-bold">{winner.video_id.caption}</p>
        </div>
        {/* like and follow */}
        <div className="flex justify-center justify-items-center space-x-3">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Icons/favorites-filled"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <path
                d="M17.25,2 C15.4228563,2.00212494 13.6778916,2.7595689 12.4285714,4.09285714 C10.575025,2.11462434 7.70116276,1.46934993 5.17982124,2.46527982 C2.65847973,3.46120972 1.00133468,5.89623201 1,8.60714286 C1,15.1857143 11.6107143,21.6428571 12.0714286,21.8964286 C12.2978089,22.0324379 12.5807625,22.0324379 12.8071429,21.8964286 C13.2464286,21.6428571 23.8571429,15.1857143 23.8571429,8.60714286 C23.8532061,4.95975052 20.8973923,2.00393675 17.25,2 Z"
                id="Shape"
                fill={primaryColor}
              ></path>
            </g>
          </svg>
          <span>{winner.video_id.likes}</span>
        </div>
      </div>
    </div>
  );
}

export default WinnerCard;
