import React, { useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Transition } from "@headlessui/react";
import poweredBy from "../../assets/images/Powered-by.png";
import CountdownComponent from "../Countdown/Countdown";

function Navbar({ competition }) {
  const navRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { primaryColor, secondaryColor, logos, powerUrl, url } =
    competition.settings;
  return (
    <div>
      <nav style={{ background: secondaryColor }}>
        <div className="">
          <div className="bg-[rgba(17,17,17,0.15)] h-[30px] lg:h-[40px]">
            <div className="max-w-5xl mx-auto flex justify-center items-center">
              <div>
                <span className="text-[10px] lg:text-[20px] font-semibold text-white">
                  Powered By:{" "}
                </span>
              </div>
              <a
                className="w-[50px] lg:w-[auto] h-[30px] lg:h-[40px] mx-[10px] lg:py-[5px] block"
                href={powerUrl ? powerUrl : "https://www.shaga3app.com/en/"}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-full h-full object-contain"
                  src={
                    logos
                      ? logos.powerLogo
                        ? logos.powerLogo
                        : poweredBy
                      : poweredBy
                  }
                  alt="powerbylogo"
                />
              </a>
            </div>
          </div>
          <div className="flex items-center justify-around h-20 w-full px-[10px] max-w-5xl mx-auto">
            <div className="w-[25%] my-auto h-[80%]">
              <a href={url ? url : "https://www.shaga3app.com/en/"}>
                <img
                  className="h-full object-contain"
                  src={logos ? (logos.header ? logos.header : null) : null}
                  alt="header"
                />
              </a>
            </div>
            <div className="flex-1 text-center h-full">
              <CountdownComponent />
            </div>
            <div className="hidden md:block w-[30%] h-full">
              <div className=" flex justify-between items-center h-full">
                <Link
                  to="/"
                  className="text-white hover:!text-[#191919] px-1 text-xs font-semibold w-[50%] text-center"
                >
                  All Competitons
                </Link>
                <Link
                  to={`/home/${competition._id}`}
                  className=" hover:!text-[#191919] px-1 text-xs font-semibold w-[50%] text-center"
                  style={{
                    color: location.pathname.includes("home")
                      ? "#191919"
                      : "#fff",
                  }}
                >
                  Home
                </Link>
                <Link
                  to={`/winners/${competition._id}`}
                  className="hover:!text-[#191919] px-1 text-xs font-semibold w-[50%] text-center"
                  style={{
                    color: location.pathname.includes("winners")
                      ? "#191919"
                      : "#fff",
                  }}
                >
                  Winners
                </Link>
              </div>
            </div>
            <div className="w-[50px] md:hidden flex justify-center items-center">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-white focus:outline-none"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      id="Icons/menu"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <path
                        d="M14,15.25 C14.4142136,15.25 14.75,15.5857864 14.75,16 C14.75,16.3796958 14.4678461,16.693491 14.1017706,16.7431534 L14,16.75 L3,16.75 C2.58578644,16.75 2.25,16.4142136 2.25,16 C2.25,15.6203042 2.53215388,15.306509 2.89822944,15.2568466 L3,15.25 L14,15.25 Z M21,8.25 C21.4142136,8.25 21.75,8.58578644 21.75,9 C21.75,9.37969577 21.4678461,9.69349096 21.1017706,9.74315338 L21,9.75 L3,9.75 C2.58578644,9.75 2.25,9.41421356 2.25,9 C2.25,8.62030423 2.53215388,8.30650904 2.89822944,8.25684662 L3,8.25 L21,8.25 Z"
                        id="Combined-Shape"
                        fill="#fff"
                        transform="translate(12.000000, 12.500000) scale(-1, 1) translate(-12.000000, -12.500000) "
                      ></path>
                    </g>
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {/* {(ref) => ( */}
          {() => (
            <div className="md:hidden" id="mobile-menu">
              <div ref={navRef} className="pt-2 pb-3 space-y-1 sm:px-3">
                <div>
                  <Link
                    to="/"
                    className="text-white hover:!bg-[#191919] px-2 text-xs font-semibold w-[50%] text-center"
                  >
                    All Competitons
                  </Link>
                </div>
                <div
                  className="w-full hover:bg-[#191919]"
                  style={{
                    backgroundColor: location.pathname.includes("home")
                      ? "#191919"
                      : "",
                  }}
                >
                  <Link
                    to={`/home/${competition._id}`}
                    className="text-white px-2 text-xs font-semibold w-full"
                  >
                    Home
                  </Link>
                </div>
                <div
                  className="w-ful hover:!bg-[#191919]"
                  style={{
                    backgroundColor: location.pathname.includes("winners")
                      ? "#191919"
                      : "",
                  }}
                >
                  <Link
                    to={`/winners/${competition._id}`}
                    className="text-white  px-2 text-xs font-semibold w-full"
                  >
                    Winners
                  </Link>
                </div>
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </div>
  );
}

export default Navbar;
