import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  getCountDown,
  getHomeVideos,
  setFinished,
} from "../store/actions/competitions";
import Confetti from "react-confetti";
import Navbar from "../components/Navbar/Navbar";
import HomeList from "../components/HomeList/HomeList";
import { useWindowSize } from "react-use";

function Home() {
  const { width, height } = useWindowSize();
  const dispatch = useDispatch();
  const competitionData = useSelector(
    (state) => state.competitions.competition
  );
  const navigate = useNavigate();
  const homeVideosData = useSelector((state) => state.competitions.homeVideos);
  const isFinished = useSelector((state) => state.competitions.isFinished);
  let { id } = useParams();

  useEffect(() => {
    dispatch(getHomeVideos(id));
    const interval = setInterval(() => dispatch(getHomeVideos(id)), 20000);
    return () => clearInterval(interval);
  }, [dispatch, id]);

  useEffect(() => {
    let timeout = null;
    if (isFinished) {
      timeout = setTimeout(() => {
        dispatch(setFinished(false));
        dispatch(getCountDown(id));
        navigate(`/winners/${id}`);
      }, 20000);
    }
    return () => clearInterval(timeout);
  }, [dispatch, id, isFinished, navigate]);

  return (
    <div>
      {competitionData && (
        <div className="min-h-screen h-full bg-[#FAFAFA]">
          <Navbar competition={competitionData} />
          <main>
            {homeVideosData && (
              <>
                {homeVideosData.length !== 0 ? (
                  <div className="max-w-5xl mx-auto py-6 sm:px-6 lg:px-8">
                    <HomeList
                      videos={homeVideosData}
                      defaultLogo={competitionData.settings.logos.brand}
                      primaryColor={competitionData.settings.primaryColor}
                      secondaryColor={competitionData.settings.secondaryColor}
                    />
                  </div>
                ) : (
                  <div className="max-w-5xl mx-auto my-28 py-6 sm:px-6 lg:px-8 h-full flex justify-center items-center">
                    <h5 className="text-3xl font-bold">No Videos Yet</h5>
                  </div>
                )}
              </>
            )}
            {isFinished && (
              <Confetti width={width} height={height} className="!z-[10000]" />
            )}
          </main>
        </div>
      )}
    </div>
  );
}

export default Home;
